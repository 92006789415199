// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Schedules-module__NVJTrOWGZI7bI1pn3hsV {\n  margin-top: 24px;\n}\n\n.Schedules-module__SU3YOIZNGwjo6D6caZYp {\n  position: relative;\n  margin: 20px 0;\n}\n\n.Schedules-module__NVJTrOWGZI7bI1pn3hsV .Schedules-module__NZutD9pVFmYCmUHpDHm9 {\n  padding-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./pages/schedules/Schedules.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.schedule {\n  position: relative;\n  margin: 20px 0;\n}\n\n.root .buttons {\n  padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Schedules-module__NVJTrOWGZI7bI1pn3hsV",
	"schedule": "Schedules-module__SU3YOIZNGwjo6D6caZYp",
	"buttons": "Schedules-module__NZutD9pVFmYCmUHpDHm9"
};
export default ___CSS_LOADER_EXPORT___;
