// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScheduleUserDetails-module__ehsEj2RmINv3zhLCYRr0 {\n  width: 220px;\n  padding: 10px;\n}\n\n.ScheduleUserDetails-module__Om04vuZ3Ek2IF4aztybG {\n  line-height: 16px;\n  color: var(--primary-background);\n  padding: 2px 7px;\n  border-radius: 4px;\n  margin-bottom: 10px;\n}\n\n.ScheduleUserDetails-module___TNJospvHuWPGt1Dz980 {\n  background: #6ccf8e;\n}\n\n.ScheduleUserDetails-module__PGGSAedlZBhcojSuUczk {\n  background: #ccccdc;\n}\n\n.ScheduleUserDetails-module__L9Sonk07J5ejiJCjr__L {\n  background: rgba(204, 204, 220, 0.4);\n}\n\n.ScheduleUserDetails-module__oYerNKinjUkVkBGXUM6b {\n  width: 100%;\n  margin: 0 -11px;\n}\n\n.ScheduleUserDetails-module__QGCM4s7lLZ8Nw5kWQnP8 {\n  display: flex;\n  flex-direction: column;\n}\n\n.ScheduleUserDetails-module__rZH9NptJWHgTB7TWZdV9 {\n  color: #ccccdc;\n}\n", "",{"version":3,"sources":["webpack://./components/ScheduleUserDetails/ScheduleUserDetails.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,gCAAgC;EAChC,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".root {\n  width: 220px;\n  padding: 10px;\n}\n\n.oncall-badge {\n  line-height: 16px;\n  color: var(--primary-background);\n  padding: 2px 7px;\n  border-radius: 4px;\n  margin-bottom: 10px;\n}\n\n.oncall-badge__type_now {\n  background: #6ccf8e;\n}\n\n.oncall-badge__type_inside {\n  background: #ccccdc;\n}\n\n.oncall-badge__type_outside {\n  background: rgba(204, 204, 220, 0.4);\n}\n\n.hr {\n  width: 100%;\n  margin: 0 -11px;\n}\n\n.times {\n  display: flex;\n  flex-direction: column;\n}\n\n.icon {\n  color: #ccccdc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ScheduleUserDetails-module__ehsEj2RmINv3zhLCYRr0",
	"oncall-badge": "ScheduleUserDetails-module__Om04vuZ3Ek2IF4aztybG",
	"oncall-badge__type_now": "ScheduleUserDetails-module___TNJospvHuWPGt1Dz980",
	"oncall-badge__type_inside": "ScheduleUserDetails-module__PGGSAedlZBhcojSuUczk",
	"oncall-badge__type_outside": "ScheduleUserDetails-module__L9Sonk07J5ejiJCjr__L",
	"hr": "ScheduleUserDetails-module__oYerNKinjUkVkBGXUM6b",
	"times": "ScheduleUserDetails-module__QGCM4s7lLZ8Nw5kWQnP8",
	"icon": "ScheduleUserDetails-module__rZH9NptJWHgTB7TWZdV9"
};
export default ___CSS_LOADER_EXPORT___;
