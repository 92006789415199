// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TelegramSettings-module__hrBZC7I44JQgwsalhAd2 {\n  display: block;\n}\n\n.TelegramSettings-module__P2Sg_HuQpZ82A7ogBs02 {\n  display: flex;\n  justify-content: space-between;\n}\n\n.TelegramSettings-module__hmWoDLoQfISod520PgZd {\n  text-align: center;\n  width: 725px;\n}\n\n.TelegramSettings-module__dF2OSaIV65W9BYhVO0SG > ul {\n  margin: 20px 30px;\n}\n\n.TelegramSettings-module__Gz1I59MTxcfu4U7DqsQ5 {\n  margin-left: 48px;\n  margin-right: 48px;\n  margin-top: 24px;\n}\n\n.TelegramSettings-module__E15PKShDjk1QCu80z5tM {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./pages/chat-ops/parts/tabs/TelegramSettings/TelegramSettings.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".root {\n  display: block;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.telegram-infoblock {\n  text-align: center;\n  width: 725px;\n}\n\n.features-list > ul {\n  margin: 20px 30px;\n}\n\n.infoblock-text {\n  margin-left: 48px;\n  margin-right: 48px;\n  margin-top: 24px;\n}\n\n.infoblock-icon {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "TelegramSettings-module__hrBZC7I44JQgwsalhAd2",
	"header": "TelegramSettings-module__P2Sg_HuQpZ82A7ogBs02",
	"telegram-infoblock": "TelegramSettings-module__hmWoDLoQfISod520PgZd",
	"features-list": "TelegramSettings-module__dF2OSaIV65W9BYhVO0SG",
	"infoblock-text": "TelegramSettings-module__Gz1I59MTxcfu4U7DqsQ5",
	"infoblock-icon": "TelegramSettings-module__E15PKShDjk1QCu80z5tM"
};
export default ___CSS_LOADER_EXPORT___;
